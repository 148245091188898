import React from "react";
import { Link } from "gatsby";
import Fade from "react-reveal"


const TeamMember = ({ teamMember, lastRow }) => {
  const fullName = `${teamMember.node.first_name} ${teamMember.node.last_name}`
  const handle = fullName.toLowerCase().split(' ').join('-');
  const url = `/our-talent/${handle}`
  const designation = teamMember.node.designation.type;

  return (
    <Fade duration={500}>
      <div className={`${lastRow} relative flex flex-col justify-between h-full col-span-2 mb-14 sm:mb-0 team-member`}>
        <div>
          <div className="text-center">
            <img className="m-auto w-8/12 md:w-11/12 lg:w-full rounded-full" src={teamMember.node.headshot.publicURL} alt={fullName}/>
            <h5 className={`${ designation === 'team' ? 'text-orange' : 'text-pink'} text-3xl md:text-2xl lg:text-3xl font-bold pt-5 pb-3`}>
              {teamMember.node.first_name} <br className="hidden md:inline-block lg:hidden"/>{teamMember.node.last_name}</h5>
            <p className="text-white text-opacity-50 leading-6 pb-8">
              {teamMember.node.title} <br/>
              {teamMember.node.office.city_state} 
            </p>
          </div>
          {teamMember.node.link_to_work && (
            <button className={`${designation  === 'leadership' ? 'btn bg-pink' : 'btn'} outline-none`}>View Work</button>
          )}
        </div>

        {teamMember.node.link_to_work && (
          <Link to={url} className={`${lastRow} absolute top-0 left-0 h-full w-full`}></Link>
        )}
      </div>    
    </Fade>
  )
}

export default TeamMember;