import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TeamMember from "../components/teamMember";
import Header from "../components/header";
import ReactMarkdown from "react-markdown"
import Fade from "react-reveal/Fade";

const ourTeam = ({ data }) => {
  const noLeaders = data.leadership.edges.length;
  const noTeamMembers = data.team.edges.length;
  const lastRow = (number) => {
    if (number % 3 === 1 ) {
      return 'remainder1'
    } else if (number % 3 === 2) {
      return 'remainder2'
    } else {
      return 'remainderNone';
    }
  }

  return (
    <Layout>
      <Seo title="Our Talent" />
      <Header />
      <div className="pt-32 md:pt-40 lg:pt-60">
        <h2 className="intro-title">Our Leadership</h2>
        <ReactMarkdown className="intro-paragraph">{data.leadership.edges[0].node.designation.designation_intro}</ReactMarkdown>
        <Fade>
          <div className="team-grid">
            {data.leadership.edges.map(leader => (
              <TeamMember key={leader.node.id} teamMember={leader} lastRow={lastRow(noLeaders)} />
            ))}
          </div>
        </Fade>
      </div>
    
      <div className="pt-20 md:pt-28 lg:pt-36">
        <Fade><h2 className="intro-title text-orange">Our Team</h2></Fade>
        <Fade><ReactMarkdown className="intro-paragraph text-white">{data.team.edges[0].node.designation.designation_intro}</ReactMarkdown></Fade>
          <div className="team-grid">
            {data.team.edges.map((teamMember, i) => (
              <TeamMember key={i} teamMember={teamMember} lastRow={lastRow(noTeamMembers)} />
            ))}
          </div>
      </div>
    </Layout>
  )
};

export default ourTeam

export const pageQuery = graphql`
  query {
    leadership: allStrapiTeammembers(
      filter: {designation: {type: {eq: "leadership"}}}
      sort: {fields: leadership_order}
    ) {
      edges {
        node {
          ...StrapiTeamMembersFragment
        }
      }
    }
    team: allStrapiTeammembers(
      filter: {designation: {type: {eq: "team"}}}
      sort: {fields: last_name}
    ) {
      edges {
        node {
          ...StrapiTeamMembersFragment
        }
      }
    }
  }
`

export const query = graphql`
  fragment StrapiTeamMembersFragment on StrapiTeammembers {
    id
    first_name
    title
    last_name
    leadership_order
    link_to_work
    designation {
      type
      designation_intro
    }
    office {
      city_state
    }
    headshot {
      publicURL
    }
  }
`
